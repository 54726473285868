<template>
  <div>
    <b-overlay :show="isLoading">
      <b-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th />
                <th>Created on</th>
                <th>Sent</th>
                <th>To</th>
                <th>category</th>
                <th style="width: 200px">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="data in messages"
                :key="data.id"
              >
                <td>
                  <b-link
                    :to="{ name: 'host:manage:message-detail', params: { id: data.id} }"
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="EyeIcon"
                    />
                  </b-link>
                </td>
                <td>{{ data.creationTime | formatDateTime }}</td>
                <td>
                  <feather-icon
                    v-if="data.sentOn"
                    icon="CheckCircleIcon"
                  />
                </td>
                <td>{{ data.to }}</td>
                <td>{{ data.category }}</td>
                <td>
                  <b-button
                    v-b-tooltip.hover
                    size="sm"
                    title="Download as .eml"
                    @click="download(data.id)"
                  >
                    <feather-icon icon="MailIcon" />
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
          <b-pagination
            v-model="currentPage"
            class="mt-2"
            :total-rows="totalDataCount"
            align="right"
          />
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BPagination, BOverlay, BButton, VBTooltip, BLink,
} from 'bootstrap-vue'

import MessageService from '@/service/message.service'

export default {
  components: {
    BCard,
    BPagination,
    BOverlay,
    BButton,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      messages: [],
      totalDataCount: 0,
      dataPerPage: 10,
      currentPage: 1,
      isLoading: false,
    }
  },
  computed: {
    requestSkipCount() {
      return this.dataPerPage * (this.currentPage - 1)
    },
  },
  watch: {
    currentPage() {
      this.loadData()
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    download(id) {
      MessageService.downloadAsync(id, { disableTenantFilter: true })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          const url = window.URL.createObjectURL(new Blob([result.data]))
          const link = document.createElement('a')
          link.href = url

          if (result.headers['content-type'] === 'application/vnd.ms-outlook') {
            link.setAttribute('download', `${id}.eml`)
          } else {
            link.setAttribute('download', `${id}.txt`)
          }
          document.body.appendChild(link)
          link.click()
          link.remove()// you need to remove that elelment which is created before.
        })
    },
    loadData() {
      this.isLoading = true
      MessageService.getListAsync(
        {
          maxResultCount: this.dataPerPage,
          skipCount: this.requestSkipCount,
          sorting: 'CreationTime desc',
        },
        { disableTenantFilter: true },
      )
        .then(result => {
          this.messages = result.items
          this.totalDataCount = result.totalCount
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
